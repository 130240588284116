<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'

export default {
  data () {
    return {
      couponCodes: []
    }
  },

  methods: {
    async getCouponCodes () {
      await axios
        .get(`${BeaconapiUrl}/api/ecommerce/coupons/dropdown`)
        .then((result) => {
          this.couponCodes = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
