<template>
  <b-row>
    <b-col xxs="12" lg="12" xl="12" class="pr-6 display">
      <confirmation ref="Confirmation"></confirmation>
      <b-card class="mb-4 mr-8" :title="title">
        <b-form
          @submit.stop.prevent="onValidateAffiliateFormSubmit"
          class="av-tooltip tooltip-label-top"
        >
        <b-row>
            <b-colxx sm="6">
              <b-form-group label="Affiliate Type">
                <multiselect
                  v-model="affiliate.types"
                  :options="affiliateTypes"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Affiliate Type"
                  track-by="affiliateTypeID"
                  :custom-label="affiliateCustomLabel"
                  @select='affiliateTypeSelected'
                  @remove='affiliateTypeDeselected'
                ></multiselect>
                <div class="text-danger" v-if="!$v.affiliate.types.required && $v.affiliate.types.$dirty">This field is required</div>
              </b-form-group>
            </b-colxx>
            <b-col sm="6">
              <b-form-group label="Active">
                <switches
                  v-model="affiliate.active"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Affiliate Name">
                <b-form-input
                  v-model.trim="affiliate.companyName"
                  rows="3"
                  @input="$v.affiliate.companyName.$touch"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.affiliate.companyName.required && $v.affiliate.companyName.$dirty">This field is required</div>
              </b-form-group>
            </b-colxx>
            <b-col sm="6">
              <b-form-group label="Corporate Customer">
                <switches
                  v-model="affiliate.corporateCustomer"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Street Address 1">
                <vue-google-autocomplete
                  id="map"
                  ref="autocomplete"
                  classname="form-control"
                  placeholder="Search Here..."
                  v-on:placechanged="getAddressData"
                  v-on:change="handleAddressChange"
                  country="us">
                </vue-google-autocomplete>
                <div class="text-danger" v-if="!$v.affiliate.streetAddress.required && $v.affiliate.streetAddress.$dirty">This field is required</div>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="Street Address 2">
                <b-form-input
                  v-model.trim="affiliate.streetAddress2"
                  rows="3"
                ></b-form-input>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="City">
                <b-form-input
                  v-model.trim="affiliate.city"
                  rows="3"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.affiliate.city.required && $v.affiliate.city.$dirty">This field is required</div>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="State">
                <multiselect
                  v-model="selectedState"
                  :options="states"
                  :close-on-select="true"
                  placeholder="Select State"
                  track-by="stateID"
                  label="stateName"
                  @select="stateSelected"
                ></multiselect>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Zip">
                <b-form-input
                  v-model.trim="affiliate.zip"
                  rows="3"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.affiliate.zip.required && $v.affiliate.zip.$dirty">This field is required</div>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="Website">
                <b-form-input
                  v-model.trim="affiliate.website"
                  rows="3"
                  :state="!$v.affiliate.website.$error"
                  @input="$v.affiliate.website.$touch"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="!$v.affiliate.website.maxLengthValue"
                >
                  Must be fewer than 255 characters.
                </div>
                <div class="text-danger" v-if="!$v.affiliate.website.url && $v.affiliate.website.$dirty"> Must be a valid URL.</div>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Programs">
                <multiselect
                  v-model="affiliate.programs"
                  :options="groupedPrograms"
                  :multiple="true"
                  group-values="options"
                  group-label="label"
                  track-by="programID"
                  label="dropdownLabel"
                  placeholder="Select Programs"
                  :close-on-select="false"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="Operates In">
                <multiselect
                  v-model="affiliate.states"
                  :options="states"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select States"
                  track-by="stateID"
                  label="stateName"
                  :custom-label="stateCustomLable"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="Approved Surveyors">
                <multiselect
                  v-model="affiliate.affiliateSurveyors"
                  :options="approvedSurveyors"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Approved Surveyors"
                  track-by="employeeID"
                  :custom-label="surveyorCustomLable"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="6">
              <b-form-group label="Coupon Code">
                <multiselect
                  v-model="affiliate.couponCode"
                  :options="couponCodes"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Select Coupon Code"
                  track-by="couponCodeID"
                  label="couponCodeName"
                  :custom-label="couponCodesCustomLable"
                ></multiselect>
              </b-form-group>
            </b-colxx>
          </b-row>
          <div v-if="title === 'Edit Affiliates'">
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Created Date">
                <p>{{ formatDate(affiliate.dateCreated) }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Created By">
                <p>{{ affiliate.createdBy }}</p>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Edited Date">
                <p>{{ formatDate(affiliate.dateLastModified) }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Edited By">
                <p>{{ affiliate.lastModifiedBy }}</p>
              </b-form-group>
            </b-colxx>
          </b-row>
          </div>
          <div v-if="newAffiliate">
            <br><br>
          <h3 id="customer-inquiry-title" class="subtitle">Affiliate Contact</h3>
          <br>
          <b-row>
            <b-colxx sm="4">
              <b-form-group label="First Name">
                <b-form-input
                  v-model.trim="affiliate.contact.firstName"
                  placeholder="First Name"
                  rows="3"
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.firstName.$error"
                  v-if="!$v.affiliate.contact.firstName.alpha"
                >
                  First Name must be composed of only letters
                </div>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.firstName.$error"
                  v-else-if="!$v.affiliate.contact.firstName.maxLength"
                >
                  Maximum Characters Allowed 50
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="4">
              <b-form-group label="Last Name">
                <b-form-input
                  v-model.trim="affiliate.contact.lastName"
                  placeholder="Last Name"
                  rows="3"
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.lastName.$error"
                  v-if="!$v.affiliate.contact.lastName.alpha"
                >
                  Last Name must be composed of only letters
                </div>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.lastName.$error"
                  v-else-if="!$v.affiliate.contact.lastName.maxLength"
                >
                  Maximum Characters Allowed 50
                </div>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="4">
              <b-form-group label="Phone Number">
                <b-form-input
                  v-model.trim="affiliate.contact.phoneNumber"
                  v-mask="phoneMask"
                  placeholder="Phone Number"
                  rows="3"
                ></b-form-input>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="4">
              <b-form-group label="Ext">
                <b-form-input
                  v-mask="extMask"
                  v-model.trim="affiliate.contact.phoneNumberExtension"
                  placeholder="Ext."
                  rows="3"
                ></b-form-input>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="4">
              <b-form-group label="Email">
                <b-form-input
                  v-model.trim="affiliate.contact.emailAddress"
                  placeholder="Email"
                  rows="3"
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.emailAddress.$error"
                  v-if="!$v.affiliate.contact.emailAddress.email"
                >
                  Enter a valid email address
                </div>
                <div
                  class="text-danger"
                  :state="!$v.affiliate.contact.emailAddress.$error"
                  v-else-if="!$v.affiliate.contact.emailAddress.maxLength"
                >
                  Maximum Characters Allowed 50
                </div>
              </b-form-group>
            </b-colxx>
          </b-row>
          </div>
          <b-row >
            <b-col xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelAffiliatesForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-col>
            <b-col  lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { validationMixin } from 'vuelidate'
import Switches from 'vue-switches'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import EcommerceMixin from '../../../mixins/EcommerceMixin.vue'
import ApplicationUserMixin from '../../../mixins/ApplicationUserMixin.vue'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import CompanyAddressMixin from '../../../mixins/CompanyAddressMixin.vue'
import Multiselect from 'vue-multiselect'
import ProgramMixin from '../../../mixins/ProgramMixin.vue'
import moment from 'moment/moment.js'
import DirtyDataMixin from '../../../mixins/DirtyDataMixin.vue'
import { mapMutations } from 'vuex'
import bus from '../../../main'
import PhoneMixin from '../../../mixins/PhoneMixin.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import confirmation from '../../../components/Common/Confirmation.vue'

const { required, maxLength, email, alpha, helpers } = require('vuelidate/lib/validators')

const url = helpers.regex(
  'url',
  /^((http|ftp|https):\/\/)?(www\.)?([\w-]+\.)+(com\b|edu\b|biz\b|gov\b|in(?:t|fo)\b|mil\b|net\b|org\b|[a-z][a-z]\b)(\/[\w\- ./?]*)?$/
)

export default {
  components: {
    switches: Switches,
    multiselect: Multiselect,
    'vue-google-autocomplete': VueGoogleAutocomplete,
    Confirmation: confirmation
  },
  props: {
    affiliateID: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      newAffiliate: false,
      title: null
    }
  },
  async created () {
    // Passing these bool to mapAffiliateTypes, which will handle enabling all multiselect options.
    var newAffiliate = false

    if (!this.affiliateID) {
      this.title = 'Add New Affiliate'
      this.affiliate = {
        companyName: '',
        streetAddress: '',
        city: '',
        zip: '',
        webSite: '',
        states: [],
        affiliateSurveyors: [],
        couponCodeID: null,
        stateID: null,
        active: true,
        programs: [],
        types: [],
        contact: {
          firstName: null,
          lastName: null,
          phoneNumber: null,
          phoneNumberExtension: null,
          emailAddress: null
        }
      }
      newAffiliate = true
    } else {
      this.title = 'Edit Affiliates'
      await this.getAffiliateByID(this.affiliateID)
      this.$refs.autocomplete.update(this.affiliate.streetAddress || '')
    }
    this.initDirtyDataWatcher('affiliate')
    await this.getAllSalesMembers()
    await this.getAllProgramsByType()
    await this.getStates()
    await this.getCouponCodes()
    await this.getApprovedSurveyors()
    await this.getAllAffiliateTypes()

    // Method initializes multiselect by enabling/disabling options as necessary.
    this.mapAffiliateTypes(newAffiliate)

    this.initDirtyDataWatcher('affiliate')

    bus.$on('saveCompany', () => {
      this.saveAffiliate()
    })
  },
  mixins: [
    validationMixin,
    AffiliateMixin,
    EcommerceMixin,
    ApplicationUserMixin,
    CompanyMixin,
    ProgramMixin,
    CompanyAddressMixin,
    DirtyDataMixin,
    PhoneMixin
  ],
  validations: {
    affiliateID: {},
    affiliate: {
      types: {
        required
      },
      companyName: {
        required
      },
      streetAddress: {
        required
      },
      city: {
        required
      },
      zip: {
        required
      },
      website: {
        maxLengthValue: maxLength(255),
        url
      },
      contact: {
        firstName: {
          alpha,
          maxLength: maxLength(50)
        },
        lastName: {
          alpha,
          maxLength: maxLength(50)
        },
        emailAddress: {
          email,
          maxLength: maxLength(50)
        }
      }
    }
  },
  beforeDestroy () {
    bus.$off('saveAffiliate')
  },
  methods: {
    ...mapMutations([
      'setPreserveMessageAfterPush',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    affiliateCustomLabel ({ affiliateTypeID }) {
      const affiliateTypes = this.affiliateTypes.find(
        affiliate => affiliate.affiliateTypeID === affiliateTypeID
      )
      return affiliateTypes ? affiliateTypes.affiliateTypeDescription : ''
    },
    stateCustomLable ({ stateID }) {
      const states = this.states.find(states => states.stateID === stateID)
      return states ? states.stateName : ''
    },
    couponCodesCustomLable ({ couponCodeID }) {
      const couponCode = this.couponCodes.find(coupons => coupons.couponCodeID === couponCodeID)
      return couponCode ? `${couponCode.description} - ${couponCode.amount}` : ''
    },
    surveyorCustomLable ({ employeeID }) {
      const surveyor = this.approvedSurveyors.find(surveyor => surveyor.employeeID === employeeID)
      return surveyor ? `${surveyor.firstName} ${surveyor.lastName}` : ''
    },
    async getApprovedSurveyors () {
      await this.getASPNetUsersByRole('Approved Surveyor')
    },
    onValidateAffiliateFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.saveAffiliate(this.affiliate)
      }
    },
    async saveAffiliate (affiliate) {
      // Common statements
      const goToSave = async () => {
        await this.addNewAffiliate(affiliate)
        if (this.statusCode === 200) {
          this.setDirtyData(false)
          this.setPreserveMessageAfterPush(true)
          this.$router.push({ name: 'affiliates-grid' })
        }
      }
      const { companyName, contact, affiliateID } = this.affiliate
      if (!affiliateID && (contact.phoneNumber !== null || '')) {
        contact.phoneNumber = this.removePhoneMask(contact.phoneNumber)
      }

      if (affiliate.affiliateSurveyors) {
        affiliate.affiliateSurveyors = affiliate.affiliateSurveyors.map(s => s.employeeID)
      }

      if (affiliate.couponCode) {
        affiliate.couponCodeID = affiliate.couponCode.couponCodeID
      }

      if (affiliateID) {
        await this.updateAffiliate(affiliate)
        if (this.statusCode === 200) {
          this.setDirtyData(false)
          this.setPreserveMessageAfterPush(true)
          this.$router.push({ name: 'affiliates-grid' })
        }
      } else {
        const isAffiliateNameAlreadyExist = await this.getAffiliateByName(companyName)
        // Ask Confimation if Affiliate Name already exists
        if (isAffiliateNameAlreadyExist) {
          this.$refs.Confirmation.show({
            title: 'Warning',
            message: `There is already an existing affiliate record with the name ${companyName}, would you still like to create this record?`,
            okButton: 'Save',
            cancelButton: 'Cancel',
            exitFunction: (e) => {
            }
          }).then(async result => {
            if (result) {
              goToSave()
            }
          })
        } else {
          goToSave()
        }
      }
    },
    cancelAffiliatesForm () {
      this.$router.push({
        name: 'affiliates'
      })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD, hh:mm A').format('MM/DD/YYYY h:mm A')
      }
    },
    stateSelected (selectedOption) {
      this.affiliate.stateID = selectedOption.stateID
    },
    mapAffiliateTypes (newAffiliate) {
      // Providing the option to explicitly select 'None' in multiselect as an affiliate type per requirements.
      // If no type is returned when editing existing affiliate, disable all other options. Otherwise, all type options enabled.
      this.affiliateTypes = this.affiliateTypes.map((a) => {
        return {
          affiliateTypeDescription: a.affiliateTypeDescription,
          affiliateTypeID: a.affiliateTypeID
        }
      })
    },
    affiliateTypeSelected (option) {
    },
    affiliateTypeDeselected (option) {
    },
    getAddressData: function (addressData) {
      const { street_number: streetNumber = '', route = '', locality = '', administrative_area_level_1: stateName = '', postal_code: postalCode = '' } = addressData
      const stateId = this.states.find(({ stateCode }) => stateCode === stateName).stateID || ''
      this.affiliate = {
        ...this.affiliate,
        streetAddress: `${streetNumber} ${route}`,
        city: locality,
        stateID: stateId,
        zip: postalCode
      }
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`)
      }, 500)
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update('')
    }
  },
  computed: {
    selectedState: {
      get () {
        return this.states.find(state => state.stateID === this.affiliate.stateID)
      },
      set () {
        this.states.stateID = this.affiliate.stateID
      }
    }
  }
}
</script>
