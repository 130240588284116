<script>
import axios from 'axios'
import { BeaconapiUrl, NavigatorUrl, NavigatorDevUrl } from '../constants/config.js'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      statusCode: null,
      approvedSurveyors: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getASPNetUsersByRole () {
      await axios
        .get(`${BeaconapiUrl}/api/authorization/employees/by-role`)
        .then(result => {
          this.approvedSurveyors = result.data
          return result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async lockOrUnlockUserLogin (userID, isLocked) {
      await axios
        .post(`${BeaconapiUrl}/api/authorization/${userID}/lockout/${isLocked}`)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async resendValidation (email) {
      await axios
        .post(`${BeaconapiUrl}/api/applicationuser/resend-validation/${window.btoa(email)}
`, email)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async activeDeactivateUser (payload) {
      await axios
        .post(`${BeaconapiUrl}/api/logins/active`, payload)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async impersonate (email) {
      await axios
        .post(`${BeaconapiUrl}/api/applicationuser/impersonate`, { email: email })
        .then(result => {
          this.statusCode = result.status
          delete result.data.cclogin
          delete result.data.message
          delete result.data.status
          const params = JSON.stringify(result.data)
          window.open(`${localStorage.getItem('api-environment') === 'live' ? NavigatorUrl : NavigatorDevUrl}/impersonate?userData=${params}`)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    }
  }
}
</script>
